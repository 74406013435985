import axios from 'axios'

const http = axios.create({
  baseURL: process.env.REACT_APP_VIDEO_COMMERCE_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

class VideoCommerceService {
  getStoreById(id) {
    return new Promise((resolve, reject) => {
      http
        .get(`/stores/${id}`)
        .then(function (response) {
          if (response.status === 200) {
            resolve({ store: response.data })
          } else {
            reject({ status: response.status, message: 'Store not existent' })
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
}

const instance = new VideoCommerceService()
Object.freeze(instance)

export default instance
