import React from 'react'
import { CallHomeContainer } from '../../pages/oneToOneLayout'
import { useSelector } from 'react-redux'
import { selectVideoCommerceStore } from '../../reducers/storeSlice'
import {
  StyledPhoneContainer,
  ItemsContainer,
  Paragraph,
  CustomLogo,
} from '../SellerNotAvailable/SellerNotAvailable.styles'
import { useDevices } from '@gojiraf/responsive'
import { Button } from '../Kit/Buttons/Button'
import styled from 'styled-components'
import { GJLogo } from '../GJKit/newGJLogo'

export const RedirectCard = () => {
  const { isDesktop } = useDevices()
  const store = useSelector(selectVideoCommerceStore)
  const { redirectBackground, companyLogo, redirectText, redirectButtonText, redirectButtonUrl } =
    store

  return (
    <CallHomeContainer
      isMobile={!isDesktop}
      style={{
        backgroundImage: redirectBackground ? `url(${redirectBackground})` : undefined,
      }}
      data-test="background-image"
    >
      <StyledPhoneContainer>
        <StyledGJLogo data-test="home-page-gojiraf-logo" />
        <CustomLogo src={companyLogo} alt={'Store logo'} />
        <ItemsContainer>
          <Paragraph>{redirectText ?? 'Seguí la transmisión en vivo'}</Paragraph>
        </ItemsContainer>
        <ItemsContainer>
          <Button
            variant="contained"
            color="primary"
            href={redirectButtonUrl ?? 'https://www.instagram.com/gojiraf/#'}
            target="_blank"
          >
            {redirectButtonText ?? 'Ingresar'}
          </Button>
        </ItemsContainer>
      </StyledPhoneContainer>
    </CallHomeContainer>
  )
}

const StyledGJLogo = styled(GJLogo)`
  align-self: flex-end;
`
