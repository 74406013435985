"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  loggerService: () => loggerService,
  useLogger: () => useLogger
});
module.exports = __toCommonJS(src_exports);

// src/useLogger.ts
var import_react_query = require("@tanstack/react-query");

// src/coreClient.ts
var import_axios = __toESM(require("axios"));
var { REACT_APP_CORE_URL } = process.env;
var coreClient = import_axios.default.create({
  baseURL: `${REACT_APP_CORE_URL}/api`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

// src/loggerService.ts
var addLog = (newLog) => coreClient.post("/Logs", {
  event: newLog.event,
  // index: 'events_'
  //   .concat(newLog.event)
  //   .concat('_companies_')
  //   .concat(companyId),
  data: __spreadProps(__spreadValues({}, newLog.data), {
    type: newLog.event,
    eventType: newLog.event
  })
});
var loggerService = {
  addLog
};

// src/useLogger.ts
var isLoggerEnabled = process.env.REACT_APP_FEATURE_FLAG_LOGGER === "true";
var useLogger = () => {
  const { mutate } = (0, import_react_query.useMutation)({
    // mutationFn: loggerService.addLog(companyId),
    mutationFn: loggerService.addLog
  });
  const addLog2 = (data) => {
    if (isLoggerEnabled === true) {
      mutate(data);
    }
  };
  return {
    addLog: addLog2
  };
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  loggerService,
  useLogger
});
