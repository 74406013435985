import React, { useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import { useDevices } from '@gojiraf/responsive'

const PhoneContainer = ({ children, ...props }) => {
  const { isDesktop } = useDevices()
  const StyledPhoneContainerRef = useRef(null)
  const inIframe = sessionStorage.getItem('inIframe') === 'true'

  const setWidthBasedOnHeight = debounce(async () => {
    if (StyledPhoneContainerRef.current) {
      StyledPhoneContainerRef.current.style.width =
        (StyledPhoneContainerRef.current.clientHeight * 9) / 16 + 'px'
    }
  }, 100)

  useEffect(() => {
    if (isDesktop) {
      setWidthBasedOnHeight()
      window.addEventListener('resize', setWidthBasedOnHeight)
      return () => {
        window.removeEventListener('resize', setWidthBasedOnHeight)
      }
    } else {
      if (StyledPhoneContainerRef.current) {
        StyledPhoneContainerRef.current.style.width = '100%'
      }
    }
  }, [isDesktop])

  return (
    <StyledPhoneContainer
      isMobile={!isDesktop}
      inIframe={inIframe}
      ref={StyledPhoneContainerRef}
      component="section"
      height={window.innerHeight}
      elevation={12}
      {...props}
    >
      {children}
    </StyledPhoneContainer>
  )
}

const StyledPhoneContainer = styled(Paper)`
  height: ${({ isMobile, height, inIframe }) =>
    inIframe && isMobile ? '100vh' : isMobile ? `${height}px` : '87.5vh'};
  height: ${({ isMobile, inIframe }) =>
    inIframe && isMobile ? '100dvh' : isMobile ? `100dvh` : '87.5dvh'};
  margin: ${({ isMobile }) => (isMobile ? '0' : '1rem')};
  border-radius: ${({ isMobile }) => (isMobile ? '0' : '1rem')};
  transition: width 0.3s ease, height 0.3s ease;
  position: relative;
  overflow: hidden;
`

PhoneContainer.propTypes = {
  children: PropTypes.any,
}

export { PhoneContainer }
