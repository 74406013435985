import { useEffect } from 'react'
import CallService from '../services/callService'
import { useGoogleAnalytics, useMatomoAnalytics } from '@gojiraf/analytics'
import { create } from 'zustand'
import { useSelector } from 'react-redux'
import { isIOS, isSafari } from 'react-device-detect'
import {
  selectDisplayFinishEventCountdownstate,
  selectDisplayStartEventCountdownstate,
} from '../reducers/callSlice'

const usePIPStore = create((set) => ({
  isEnabled: false,
  setEnabled: (isEnabled) => set({ isEnabled }),
  canPIP: false,
  setCanPIP: (canPIP) => set({ canPIP }),
  togglePIP: () => set((state) => ({ isEnabled: !state.isEnabled })),
}))

export const usePIP = (initializeListeners = false) => {
  const { gaEventTracker } = useGoogleAnalytics()
  const { matomoTrackEvent } = useMatomoAnalytics()

  const isEnabled = usePIPStore((state) => state.isEnabled)
  const setEnabled = usePIPStore((state) => state.setEnabled)
  const canPIP = usePIPStore((state) => state.canPIP)
  const setCanPIP = usePIPStore((state) => state.setCanPIP)
  const togglePIP = usePIPStore((state) => state.togglePIP)
  const displayFinishEventCountdown = useSelector(selectDisplayFinishEventCountdownstate)
  const displayStarthEventCountdown = useSelector(selectDisplayStartEventCountdownstate)
  const videoElement = document.querySelector('[id="video__streaming"] video')
  if (videoElement && isSafari) {
    videoElement.autoPictureInPicture = true
  }

  const handleIOSLeavePIP = async () => {
    const videoTrack = CallService.getCurrentVideoTrack()
    videoTrack.stop()
    videoTrack.play('video__streaming', { fit: 'cover' })
    await videoElement.play()
  }

  const handleEnterPictureInPicture = () => {
    setEnabled(true)
    window.parent.postMessage({ type: 'enterpictureinpicture' }, '*')
  }
  const handleLeavePictureInPicture = () => {
    setEnabled(false)
    window.parent.postMessage({ type: 'leavepictureinpicture' }, '*')
    if (isIOS) handleIOSLeavePIP()
  }
  const handleMessage = (event) => {
    if (event.data?.type === 'MUTE_AUDIO') {
      CallService.muteAudioTracks()
    }
    if (event.data?.type === 'UNMUTE_AUDIO') {
      CallService.unMuteAudioTracks()
    }
  }

  const addEventListeners = () => {
    document.addEventListener('enterpictureinpicture', handleEnterPictureInPicture)
    document.addEventListener('leavepictureinpicture', handleLeavePictureInPicture)
    window.addEventListener('message', handleMessage)
  }

  const removeEventListeners = () => {
    document.removeEventListener('enterpictureinpicture', handleEnterPictureInPicture)
    document.removeEventListener('leavepictureinpicture', handleLeavePictureInPicture)
    window.removeEventListener('message', handleMessage)
  }

  const closePIP = async () => {
    try {
      setEnabled(false)
      await document.exitPictureInPicture()
      if (isIOS || isSafari) await videoElement.webkitSetPresentationMode('inline')
    } catch (err) {
      console.log(err)
    }
  }

  const openPIP = async () => {
    try {
      setEnabled(true)
      if (isIOS || isSafari) {
        await videoElement.webkitSetPresentationMode('picture-in-picture')
      } else {
        await videoElement.requestPictureInPicture()
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (displayFinishEventCountdown || displayStarthEventCountdown) {
      setCanPIP(false)
      setEnabled(false)
    } else {
      setCanPIP(true)
    }
  }, [displayFinishEventCountdown, displayStarthEventCountdown])

  useEffect(() => {
    if (isEnabled === true) {
      openPIP()
      gaEventTracker('Pip', 'open-pip')
      matomoTrackEvent('Pip', 'open-pip')
    } else {
      closePIP()
      gaEventTracker('Pip', 'close-pip')
      matomoTrackEvent('Pip', 'close-pip')
    }
  }, [isEnabled])

  useEffect(() => {
    const container = document.getElementById('video__streaming')
    if (!container) return
    const observer = new MutationObserver(() => {
      const videoEl = container.querySelector('video')
      if (!videoEl) {
        setEnabled(false)
      }
    })
    if (initializeListeners === true) {
      addEventListeners()
      observer.observe(container, { childList: true, subtree: true })
    }

    return () => {
      removeEventListeners()
      observer.disconnect()
    }
  }, [initializeListeners])

  return {
    isEnabled,
    canPIP,
    setEnabled,
    togglePIP,
    setCanPIP,
    handleLeavePictureInPicture,
  }
}
