import axios from 'axios'

const { REACT_APP_STORES_URL } = process.env

if (!REACT_APP_STORES_URL) {
  throw new Error('REACT_APP_STORES_URL is required')
}

export const storesClient = axios.create({
  baseURL: REACT_APP_STORES_URL,
})
